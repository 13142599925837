<template>
  <div class="profile-page">
    <!-- Cover Section -->
    <div class="cover-section">
      <v-container class="profile-container">
        <!-- Profile Header Card -->
        <v-card class="profile-header-card" elevation="3">
          <div class="profile-header-content">
            <!-- Avatar Section -->
            <div class="avatar-section">
              <v-avatar size="120" class="profile-avatar">
                <v-img
                  :src="
                    members.profile || require('../assets/Default-profile.png')
                  "
                  :alt="members.username"
                  @error="handleImageError"
                >
                  <template v-slot:placeholder>
                    <v-icon size="64" color="primary"
                      >mdi-account-circle</v-icon
                    >
                  </template>
                </v-img>
              </v-avatar>
              <v-speed-dial
                v-model="fab"
                :direction="'left'"
                :transition="'slide-y-reverse-transition'"
                class="profile-actions"
              >
                <template v-slot:activator>
                  <v-btn v-model="fab" color="primary" dark fab small>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <!-- Upload Picture Button -->
                <v-btn
                  fab
                  dark
                  x-small
                  color="green"
                  @click="uploadDialog = true"
                >
                  <v-icon>mdi-camera</v-icon>
                </v-btn>

                <!-- Edit Profile Button -->
                <v-btn fab dark x-small color="cyan" @click="editDialog = true">
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>

                <!-- Change Password Button -->
                <v-btn fab dark x-small color="blue" @click="passDialog = true">
                  <v-icon>mdi-form-textbox-password</v-icon>
                </v-btn>
              </v-speed-dial>
            </div>

            <!-- User Info -->
            <div class="user-info">
              <h2 class="text-h4 font-weight-bold">{{ members.fullname }}</h2>
              <div class="subtitle-1 grey--text">{{ members.position }}</div>
              <div class="mt-2">
                <v-chip small :color="members.active ? 'success' : 'grey'" dark>
                  {{ members.active ? "Active" : "Inactive" }}
                </v-chip>
                <v-chip
                  v-for="(role, index) in currentUser.roles"
                  :key="index"
                  small
                  class="ml-2"
                  color="primary"
                  outlined
                >
                  {{ role }}
                </v-chip>
              </div>
            </div>
          </div>
        </v-card>

        <!-- Main Content Grid -->
        <v-row class="mt-6">
          <!-- Left Column -->
          <v-col cols="12" md="4">
            <!-- Personal Info Card -->
            <v-card class="mb-4">
              <v-card-title
                class="subtitle-1 font-weight-bold d-flex align-center"
              >
                Personal Information
                <v-spacer></v-spacer>
                <v-btn icon x-small @click="editDialog = true">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item>
                  <v-list-item-icon
                    ><v-icon>mdi-email</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                    <v-list-item-title>{{ members.email }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon
                    ><v-icon>mdi-phone</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Phone</v-list-item-subtitle>
                    <v-list-item-title>{{
                      members.telnumber
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon
                    ><v-icon>mdi-card-account-details</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>NRIC</v-list-item-subtitle>
                    <v-list-item-title>{{ members.nric }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon
                    ><v-icon>mdi-map-marker</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Address</v-list-item-subtitle>
                    <v-list-item-title>{{ members.address }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>

            <!-- Emergency Contact Card -->
            <v-card>
              <v-card-title class="subtitle-1 font-weight-bold">
                Emergency Contact
              </v-card-title>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item>
                  <v-list-item-icon
                    ><v-icon>mdi-account-alert</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Contact Name</v-list-item-subtitle>
                    <v-list-item-title>{{
                      members.emcontactname
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon
                    ><v-icon>mdi-phone-alert</v-icon></v-list-item-icon
                  >
                  <v-list-item-content>
                    <v-list-item-subtitle>Contact Number</v-list-item-subtitle>
                    <v-list-item-title>{{
                      members.emcontactno
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>

          <!-- Right Column -->
          <v-col cols="12" md="8">
            <!-- Work Info Card -->
            <v-card class="mb-4">
              <v-card-title class="subtitle-1 font-weight-bold">
                Work Information
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <div class="caption grey--text">Employee ID</div>
                    <div class="subtitle-1">{{ members.empid }}</div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="caption grey--text">User Code</div>
                    <div class="subtitle-1">{{ members.usercode }}</div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="caption grey--text">Date Joined</div>
                    <div class="subtitle-1">{{ computedDate }}</div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="caption grey--text">Position</div>
                    <div class="subtitle-1">{{ members.position }}</div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <!-- Department and Branch Info -->
            <v-row>
              <v-col cols="12" md="6">
                <v-card class="mb-4" v-if="currentUser.dept">
                  <v-card-title class="subtitle-1 font-weight-bold">
                    Department
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item
                      v-for="(i, index) in currentUser.dept"
                      :key="index"
                    >
                      <v-list-item-icon
                        ><v-icon>mdi-domain</v-icon></v-list-item-icon
                      >
                      <v-list-item-content>
                        <v-list-item-title>
                          <div v-for="(m, idx) in filterDept(i)" :key="idx">
                            {{ m.name.toUpperCase() }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>

              <v-col cols="12" md="6">
                <v-card class="mb-4" v-if="currentUser.branches">
                  <v-card-title class="subtitle-1 font-weight-bold">
                    Branch
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item
                      v-for="(j, index) in currentUser.branches"
                      :key="index"
                    >
                      <v-list-item-icon
                        ><v-icon>mdi-office-building</v-icon></v-list-item-icon
                      >
                      <v-list-item-content>
                        <v-list-item-title>
                          <div v-for="(k, idx) in filterStates(j)" :key="idx">
                            {{ k.name.toUpperCase() }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Keep existing dialogs but update their styling -->
    <v-dialog v-model="editDialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          fab
          v-bind="attrs"
          v-on="on"
          class="ma-1"
          dark
          color="cyan"
          @click="editDialog"
        >
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <v-card tile>
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span>Edit Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-row align="center" no-gutters>
                <v-col cols="12" md="3" sm="6">
                  <h4>Fullname:</h4>
                </v-col>

                <v-col cols="12" md="9" sm="6">
                  <v-text-field
                    dense
                    v-model="members.fullname"
                    label="Fullname*"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" no-gutters>
                <v-col cols="12" md="3" sm="6">
                  <h4>NRIC:</h4>
                </v-col>

                <v-col cols="12" md="9" sm="6">
                  <v-text-field
                    dense
                    type="number"
                    hide-spin-buttons
                    v-model="members.nric"
                    label="NRIC*"
                    :rules="[rules.number, rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" no-gutters>
                <v-col cols="12" md="3" sm="6">
                  <h4>Tel:</h4>
                </v-col>

                <v-col cols="12" md="9" sm="6">
                  <v-text-field
                    dense
                    v-model="members.telnumber"
                    label="Tel. Number"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" no-gutters>
                <v-col cols="12" md="3" sm="6">
                  <h4>Address:</h4>
                </v-col>

                <v-col cols="12" md="9" sm="6">
                  <v-textarea
                    dense
                    auto-grow
                    v-model="members.address"
                    label="Address"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row align="center" no-gutters>
                <v-col cols="12" md="4" sm="6">
                  <h4>Emergency Contact:</h4>
                </v-col>

                <v-col cols="12" md="8" sm="6">
                  <v-text-field
                    dense
                    v-model="members.emcontactname"
                    label="Emergency Contact"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" no-gutters>
                <v-col cols="12" md="4" sm="6">
                  <h4>Emergency Contact No:</h4>
                </v-col>

                <v-col cols="12" md="8" sm="6">
                  <v-text-field
                    dense
                    v-model="members.emcontactno"
                    label="Emergency Contact Number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined small tile @click="closeEditDialog"> Close </v-btn>
          <v-btn
            :disabled="isEditDisabled"
            small
            tile
            class="warning"
            @click="save"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="passDialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-bind="attrs"
          v-on="on"
          class="ma-1"
          fab
          dark
          color="blue"
          @click="passDialog"
        >
          <v-icon dark> mdi-form-textbox-password </v-icon>
        </v-btn>
      </template>
      <v-card tile>
        <v-form>
          <v-card-title class="justify-center text-h5 grey lighten-2">
            <span>Change Password</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      disabled
                      v-model="members.email"
                      type="text"
                      name="username"
                      autoComplete="username"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="password"
                      label="Password"
                      class="mb-6"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.min, rules.strength]"
                      validate-on-blur
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                      hint="Minimum strength 75%"
                      persistent-hint
                    ></v-text-field>
                    <v-progress-linear
                      :background-opacity="opacity"
                      :color="score.color"
                      :value="score.value"
                      height="25"
                      v-model="score.value"
                    >
                      <template v-slot:default="{ value }">
                        <strong>{{ Math.ceil(value) }}%</strong>
                      </template>
                    </v-progress-linear>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[
                        rules.required,
                        rules.min,
                        password === confPassword || 'Password must match',
                      ]"
                      :type="show2 ? 'text' : 'password'"
                      @click:append="show2 = !show2"
                      v-model="confPassword"
                      label="Confirm Password*"
                      autocomplete="confPassword"
                      id="confPassword"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined small tile @click="passDialog = false">
              Close
            </v-btn>
            <v-btn
              :disabled="!valid"
              small
              tile
              class="warning"
              @click="updatePass"
            >
              Change
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadDialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-bind="attrs"
          v-on="on"
          class="ma-1"
          fab
          dark
          color="green"
          @click="uploadDialog"
        >
          <v-icon dark> mdi-cloud-upload-outline </v-icon>
        </v-btn>
      </template>
      <v-card tile>
        <v-card-title class="justify-center text-h5 grey lighten-2">
          <span>Change Profile Image</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    show-size
                    required
                    :rules="profileRules"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Pick a profile picture"
                    prepend-icon="mdi-camera"
                    label="Profile Picture*"
                    @change="selectFile"
                    v-model="currentFile"
                    name="file"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-alert
                    width="100%"
                    v-if="message"
                    border="left"
                    color="blue-grey"
                    dark
                  >
                    {{ message }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small> <br />
          <small>*max size: 2MB</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined small tile @click="closeUploadDialog"> Close </v-btn>
          <v-btn small tile class="success" @click="uploadConfirm">
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.profile-page {
  background-color: #f5f5f5;
  min-height: 100vh;
}

.cover-section {
  background: linear-gradient(135deg, #1976d2 0%, #064170 100%);
  min-height: 250px;
  padding-top: 64px;
}

.profile-container {
  max-width: 1200px;
  margin: 0 auto;
}

.profile-header-card {
  position: relative;
  margin-top: -32px;
  border-radius: 8px;
  overflow: visible;
}

.profile-header-content {
  padding: 24px;
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.avatar-section {
  position: relative;
}

.profile-avatar {
  border: 4px solid white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile-actions {
  position: absolute;
  bottom: -16px;
  right: -16px;
}

.user-info {
  flex: 1;
}

@media (max-width: 600px) {
  .profile-header-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-actions {
    position: relative;
    bottom: 0;
    right: 0;
    margin-top: 16px;
  }
}
</style>

<script>
import MemberDataService from "../services/MemberDataService";
import BranchDataService from "../services/BranchDataService";
import DeptDataService from "../services/DeptDataService";
import UploadService from "../services/UploadDocsService";
import zxcvbn from "zxcvbn";
import moment from "moment";
export default {
  name: "Profile",
  components: {
    // UploadFiles,
    // UploadService,
  },
  data: () => ({
    opacity: "",
    show1: false,
    show2: false,
    rules: {
      number: (v) =>
        /^\d+$/.test(v) || "This field is required and only accept numbers",
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      strength: (v) =>
        zxcvbn(v).score >= 3 ||
        "Please choose a stronger password. Try a mix of letters, numbers, and symbols.",

      emailMatch: () => `The password you entered don't match`,
    },
    password: "",
    confPassword: "",
    editValid: false,
    valid: false,
    editDialog: false,
    passDialog: false,
    uploadDialog: false,
    currentFile: undefined,
    progress: 0,
    message: "",
    fileInfos: [],
    profileRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    fab: false, // Add this property
    // members: "",
    // disabled: false,

    members: {
      id: null,
      username: "",
      fullname: "",
      profile: "",
      nric: "",
      email: "",
      telnumber: "",
      datejoined: "",
      position: "",
      empid: "",
      usercode: "",
      address: "",
      emcontactname: "",
      emcontactno: "",
      password: "",
      active: "",
      roles: "",
      branches: "",
      depts: "",
    },
    branches: [],
    departments: [],
  }),

  computed: {
    isEditDisabled() {
      return !this.members.nric;
    },
    score() {
      const result = zxcvbn(this.password);

      switch (result.score) {
        case 4:
          return {
            color: "light-blue",
            value: 100,
          };
        case 3:
          return {
            color: "light-green",
            value: 75,
          };
        case 2:
          return {
            color: "yellow",
            value: 50,
          };
        case 1:
          return {
            color: "orange",
            value: 25,
          };
        default:
          return {
            color: "red",
            value: 0,
          };
      }
    },
    // feedItemIcon() {
    //   return require(`../app/uploads/${this.members.profile}`);
    // },
    editDialogDisabled() {
      return !(
        this.members.fullname &&
        this.members.nric &&
        this.members.telnumber &&
        this.members.address &&
        this.members.emcontactname &&
        this.members.emcontactno
      );
    },
    currentUser() {
      return this.$store.state.auth.user;
    },

    computedDate() {
      return this.members.datejoined
        ? moment(this.members.datejoined).format("DD/MM/YYYY")
        : "";
    },
  },

  watch: {
    disabled(val) {
      val;
    },
  },
  methods: {
    handleImageError() {
      // Set profile to null to trigger fallback to default image
      this.members.profile = null;
    },
    closeEditDialog() {
      this.editDialog = false;
      this.$nextTick(() => {
        // this.members = Object.assign({});
      });
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    filterStates(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    retrieveBranches() {
      BranchDataService.getAll()
        .then((response) => {
          this.branches = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    filterDept(item) {
      return this.departments.filter((dept) => dept.id === item);
    },
    retrieveDepts() {
      DeptDataService.getAll()
        .then((response) => {
          this.departments = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveMembers() {
      MemberDataService.get(this.$store.state.auth.user.id)
        .then((response) => {
          this.members = response.data;
          // this.members = response.data.map(this.getDisplayMember);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // getDisplayMember(member) {
    //   return {
    //     id: member.id,
    //     username:
    //       member.username.length > 30
    //         ? member.username.substr(0, 30) + "..."
    //         : member.username,
    //     fullname: member.fullname,
    //     nric: member.nric,
    //     email:
    //       member.email.length > 30
    //         ? member.email.substr(0, 30) + "..."
    //         : member.email,
    //     telnumber: member.telnumber,
    //     datejoined: member.datejoined,
    //     position: member.position,
    //     empid: member.empid,
    //     usercode: member.usercode,
    //     address: member.address,
    //     emcontactname: member.emcontactname,
    //     emcontactno: member.emcontactno,

    //     active: member.active ? "Active" : "Inactive",
    //     roles: member.roles,
    //     branches: member.branches,
    //     depts: member.depts,
    //   };
    // },
    // getProfilePhoto() {
    //   // return require(`${this.members.profile}`);
    //   return require(`${this.members.profile}`);
    // },

    //     getProfilePhoto() {
    //   let baseDirectory = `http://localhost:8090/`;
    //   // return require(path.join(baseURL, `${this.members.profile}`));
    //   // return require('@/app/uploads/'+`${this.members.profile}`)
    //   // return require(path.join('@/app/uploads/'+`${this.members.profile}`));
    //   return require(path.join(baseDirectory+`${this.members.profile}`));
    // },

    selectFile(file) {
      // this.progress = 0;
      this.currentFile = file;
    },

    refreshList() {
      this.retrieveMembers();
    },

    save() {
      var editdata = {
        fullname: this.members.fullname,
        nric: this.members.nric,
        telnumber: this.members.telnumber,
        address: this.members.address,
        emcontactname: this.members.emcontactname,
        emcontactno: this.members.emcontactno,
      };
      MemberDataService.updateProfile(this.members.id, editdata)
        .then((response) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          this.editDialog = false;
          this.refreshList();
        })
        .catch((e) => {
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "error",
            text: e || toString(),
          });
          this.editDialog = false;
          this.refreshList();
        });
    },

    closeUploadDialog() {
      this.uploadDialog = false;
      this.message = "";
    },

    uploadConfirm() {
      if (!this.currentFile) {
        this.message = "Please select a file!";
        return;
      }
      if (this.currentFile) {
        this.message = "";
        UploadService.uploadPhoto(this.currentFile, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
          .then((response) => {
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "success",
              text:
                (response && response.data && response.data.message) ||
                toString(),
            });
            return UploadService.getPhotos();
          })
          .then((files) => {
            this.fileInfos = files.data;
            this.members.profile = files.data.slice(-1)[0].name;
            this.currentFile = undefined;

            var data = {
              profile: this.members.profile,
            };

            MemberDataService.updatePhoto(this.members.id, data)
              .then((response) => {
                setTimeout(() => {
                  this.$store.dispatch("setSnackbar", {
                    showing: true,
                    color: "success",
                    text:
                      (response && response.data && response.data.message) ||
                      toString(),
                  }); // Fixed: Moved closing bracket here
                }, 2500);
                this.uploadDialog = false;
                this.refreshList();
              })
              .catch((e) => {
                this.$store.dispatch("setSnackbar", {
                  showing: true,
                  color: "error",
                  text: e || toString(),
                });
                this.refreshList();
              });
          })
          .catch((e) => {
            this.progress = 0;
            // this.message = "Could not upload the file!";
            this.uploadDialog = false;
            this.$store.dispatch("setSnackbar", {
              showing: true,
              color: "error",
              // text: "Could not upload the file!",
              text:
                (e.response && e.response.data && e.response.data.message) ||
                e.message ||
                toString(),
            });
            this.currentFile = undefined;
          });
      }
    },

    updatePass() {
      var data = {
        password: this.password,
      };
      MemberDataService.changepass(this.members.id, data)
        .then((response) => {
          this.passDialog = false;
          this.$store.dispatch("setSnackbar", {
            showing: true,
            color: "success",
            text:
              (response && response.data && response.data.message) ||
              toString(),
          });
          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });

      this.passDialog = false;
    },
  },

  mounted() {
    this.retrieveBranches();
    this.retrieveDepts();
    UploadService.getPhotos().then((response) => {
      this.fileInfos = response.data;
    });

    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.retrieveMembers();
  },
};
</script>
